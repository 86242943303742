import SEO from "../../seo"
import React from "react"

const countryName = country => [
  "czech republic",
  "dominican republic",
  "netherlands",
  "philippines",
  "usa",
  "united arab emirates",
  "united kingdom",
  "united states virgin islands",
].includes(country.toLowerCase()) ? `the ${country}` : country

const StateHeader = ({ state, stateTax, countryTax }) => <>
  <SEO title={`Lottery Tax Calculator - What's The Lottery Tax in ${state}`}
       description="Lottery taxes are anything but simple, the exact amount you have to pay depends on the size of the jackpot, the state you live in, the state you bought the ticket in, and a few other factors. We've created this calculator to help you out with lottery taxes." />
  <h1>{`What's The Lottery Tax in ${state}?`}</h1>
  <p>How much money can you keep after taxes if you hit the jackpot on a lottery game like Powerball or Mega Millions
    in {state}?
    Probably much less than you think.</p>
  <p><strong>
    The state tax on lottery winnings is {stateTax}% in {state}, which you'll have to pay on top of the federal
    tax of {countryTax}%. There might be additional taxes to pay, the
    exact amount of these depends on the size of the jackpot, the
    city you live in, the state you bought the ticket in, and a few other factors.
  </strong></p>
  <h1 id="calculator">{`Lottery Tax Calculator`}</h1>
</>

const CountryHeader = ({ country }) => <>
  <SEO title={`Lottery Tax Calculator - What's The Lottery Tax in ${countryName(country)}`}
       description="Lottery taxes are anything but simple, the exact amount you have to pay depends on the size of the jackpot, the state you live in, the state you bought the ticket in, and a few other factors. We've created this calculator to help you out with lottery taxes." />
  <h1>{`What's The Lottery Tax in ${countryName(country)}`}</h1>
  <p>How much money can you keep after taxes if you hit the jackpot on a lottery game like Powerball or Mega Millions
    in {countryName(country)}?
    Probably much less than you think. This tool helps you calculate the exact amount.</p>
  <p><strong>
    Lottery taxes are anything but simple, the exact amount you have to pay depends on the size of the jackpot, the
    state/city you live in, the state you bought the ticket in, and a few other factors. We've created this calculator
    to
    help you give an estimate.
  </strong></p>
  <h1 id="calculator">{`Lottery Tax Calculator`}</h1>
</>

const GenericHeader = () => <>
  <SEO title="Lottery Tax Calculator"
       description="Lottery taxes are anything but simple, the exact amount you have to pay depends on the size of the jackpot, the state you live in, the state you bought the ticket in, and a few other factors. We've created this calculator to help you out with lottery taxes." />
  <h1 id="calculator">{`Lottery Tax Calculator`}</h1>
  <p>How much money can you keep after taxes if you hit the jackpot on a lottery game like Powerball or Mega Millions?
    Probably much less than you think. This tool helps you calculate the exact amount.</p>
  <p><strong>
    Lottery taxes are anything but simple, the exact amount you have to pay depends on the size of the jackpot, the
    state/city you live in, the state you bought the ticket in, and a few other factors. We've created this calculator
    to
    help you give an estimate.
  </strong></p>
</>

const Header = ({ country, state, countryTax, stateTax }) =>
  (state && <StateHeader state={state} stateTax={stateTax} countryTax={countryTax} />)
  || (country && <CountryHeader country={country} />)
  || <GenericHeader />

export default Header

