import React, { useState } from "react"
import { connect } from "react-redux"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import InputAdornment from "@material-ui/core/InputAdornment"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Header from "./Header"

const federalTax = 0.25
const states = {
  "Alabama": { rate: 0 },
  "Alaska": { rate: 0 },
  "Arizona": { rate: .05 },
  "Arkansas": { rate: .07 },
  "California": { rate: 0 },
  "Colorado": { rate: .04 },
  "Connecticut": { rate: .067 },
  "Delaware": { rate: 0 },
  "Florida": { rate: 0 },
  "Georgia": { rate: .06 },
  "Hawaii": { rate: 0 },
  "Idaho": { rate: .074 },
  "Illinois": { rate: .0495 },
  "Indiana": { rate: .034 },
  "Iowa": { rate: .05 },
  "Kansas": { rate: .05 },
  "Kentucky": { rate: .06 },
  "Louisiana": { rate: .05 },
  "Maine": { rate: .05 },
  "Maryland": { rate: .0875 },
  "Massachusetts": { rate: .05 },
  "Michigan": { rate: .0425 },
  "Minnesota": { rate: .0725 },
  "Mississippi": { rate: 0 },
  "Missouri": { rate: .04 },
  "Montana": { rate: .069 },
  "Nebraska": { rate: .05 },
  "Nevada": { rate: 0 },
  "New Hampshire": { rate: 0 },
  "New Jersey": { rate: .08 },
  "New Mexico": { rate: .06 },
  "New York": { rate: .0882 },
  "North Carolina": { rate: .055 },
  "North Dakota": { rate: .029 },
  "Ohio": { rate: .04 },
  "Oklahoma": { rate: .04 },
  "Oregon": { rate: .08 },
  "Pennsylvania": { rate: .0307 },
  "Rhode Island": { rate: .0599 },
  "South Carolina": { rate: .07 },
  "South Dakota": { rate: 0 },
  "Tennessee": { rate: 0 },
  "Texas": { rate: 0 },
  "Utah": { rate: 0 },
  "Vermont": { rate: .06 },
  "Virginia": { rate: .04 },
  "Washington": { rate: 0 },
  "Washington DC": { rate: .085 },
  "West Virginia": { rate: .065 },
  "Wisconsin": { rate: .0765 },
  "Wyoming": { rate: 0 },
}

const defaultCountry = "USA"
const defaultState = "New York"
const baseUrl = "/lottery-tax-calculator/"

const usd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const lookupState = stateName => Object.keys(states).find(state => state.toLowerCase() === (stateName ?? defaultState).toLowerCase())

const TaxCalculator = ({ state, country, navigate }) => {
  const [amount, setAmount] = useState(1000000)
  const [selectedState, setSelectedState] = useState(lookupState(state))
  const selectState = newState => {
    navigate(`${baseUrl}${defaultCountry}/${newState}#calculator`)
    setSelectedState(newState)
  }
  const stateTax = () => states[selectedState].rate
  try {
    return (
      <>
        <Header country={country} state={state} stateTax={stateTax() * 100} countryTax={federalTax * 100}/>
        <Paper>
          <Box p={3}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h5" component="h2">How much tax would you pay on a {usd.format(amount)} lottery prize?</Typography><br/>
              </Grid>
              <Grid item container>
                <Grid item xs={12} sm={4}>
                  <TextField select label="State" onChange={e => selectState(e.target.value)}
                             value={selectedState ?? ""}>
                    {Object.entries(states).map(state => <MenuItem value={state[0]}
                                                                   key={state[0]}>{state[0]}</MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Jackpot value" onChange={e => setAmount(parseInt(e.target.value))}
                             defaultValue={amount}
                             InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                             type="number" />
                </Grid>
              </Grid>
              <Grid item>
                <br />
                <Typography variant="h5" component="h3">
                  Total tax to pay: {usd.format(amount * federalTax + amount * stateTax())}
                </Typography>
              </Grid>
              <Grid item>
                <br />
                <Typography variant="subtitle2">
                  Breakdown:
                </Typography>
                <br />
              </Grid>
              <Grid item container>
                <Grid item xs={12} sm={4}>
                  <div>Federal tax ({federalTax * 100}%):</div>
                  <div><strong>{usd.format(amount * federalTax)}</strong></div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div>State tax in {selectedState} ({stateTax() * 100}%):</div>
                  <div><strong>{usd.format(amount * stateTax())}</strong></div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div>
                    Total tax deductions:
                  </div>
                  <div><strong>{usd.format(amount * federalTax + amount * stateTax())}</strong></div>
                </Grid>
              </Grid>
              <Grid item>
                <br />
                <Typography variant="h5" component="h3">
                  You get to keep: {usd.format(amount - amount * federalTax - amount * stateTax())}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </>
    )
  } catch {
    navigate(baseUrl)
    setSelectedState(defaultState)
    return <Paper>No data for this location.</Paper>
  }
}
const mapStateToProps = ({ gameOptions }) => ({ gameOptions })

export default connect(mapStateToProps)(TaxCalculator)
