import React from "react"
import { graphql, Link } from "gatsby"
import { Router } from "@reach/router"
import TaxCalculator from "../components/tools/TaxCalculator"

import Layout from "../components/layout"
import FAQItem from "../components/FAQItem"


const LotteryTaxCalculator = ({ data, location }) =>
  <Layout location={location} title={data.site.siteMetadata.title}>
    <Router>
      <TaxCalculator path={"/lottery-tax-calculator/:country/:state/*"}/>
      <TaxCalculator path={"/lottery-tax-calculator/:country/:state"}/>
      <TaxCalculator path={"/lottery-tax-calculator/:country"}/>
      <TaxCalculator path={"/lottery-tax-calculator"}/>
    </Router>
    <section itemScope itemType={"https://schema.org/FAQPage"}>
      <FAQItem question="Do You Always Have To Pay Taxes on Lottery Winnings?">
        <strong>Smaller prizes are tax-free. You don't have to pay federal taxes for winnings under $600.</strong> If you have any unpaid alimony or child support it can also be automatically deducted from your winnings before payout.
      </FAQItem>
      <FAQItem question="Are There Any Other Possible Fees Or Deductions On Lottery Winnings?">
        <strong>Some cities have additional taxes on lottery winnings.</strong> (For example New York City charges an additional 4%.)
      </FAQItem>
      <FAQItem question="How Accurate Is the Lottery Tax Calculator?">
        <strong>Numbers provided by the tax calculator are only estimations.</strong> The exact amount to be paid will depend on your income for the given year as it's quite a possible that you'll move up to higher <a href="/do-you-pay-taxes-on-lottery-winnings-every-year/#the-role-of-tax-brackets" target="_blank">tax
        bracket</a> because of your winnings.<br />
      </FAQItem>
      <FAQItem question="How Much Does It Cost to Use the Lottery Tax Calculator? Is It a Free Tool?">
        <strong>LottoLibrary.com's lottery tax calculator tool is free to
          use.</strong> There are no hidden fees or charges.<br />
        <i>Please note: We might show you 3<sup>rd</sup> party ads or affiliate links. You can read more about this in
          our <Link to="/terms-and-conditions/">T&amp;C document</Link>.</i>
      </FAQItem>
    </section>
    <h2>Disclaimer</h2>
    <section>
      This tool is intended for informational, educational, and entertainment purposes only. Lottery taxes are complicated and can be subject to change, you should always consult a certified professional.
    </section>
    <h2>Similar Tools</h2>
    <section>
      <ul>
        <li>
          <Link to="/lottery-number-generator/">Lottery Number Generator</Link>
        </li>
        <li>
          <Link to="/lottery-odds-calculator/">Lottery Odds Calculator</Link>
        </li>
        <li>
          <Link to="/lottery-annuity-calculator/">Lottery Annuity Payout Calculator</Link>
        </li>
        <li>
          <Link to="/best-odds/">Lottery Odds Comparison</Link>
        </li>
        <li>
          <Link to="/expected-value-calculator/">Expected Value Calculator</Link>
        </li>
      </ul>
    </section>
    <h2>Related Articles</h2>
    <section>
      <ul>
        <li>
          <Link to="/are-lottery-winnings-taxed/">Are Lottery Winnings Taxed?</Link>
        </li>
        <li>
          <Link to="/do-you-pay-taxes-on-lottery-winnings-every-year/">Do You Pay Taxes on Lottery Winnings Every Year</Link>
        </li>
        <li><Link to="/what-is-the-lottery-tax-in-washington-state/">What Is the Lottery Tax in Washington
          State?</Link></li>
        <li><Link to="/what-is-the-lottery-tax-in-washington-texas/">What Is the Lottery Tax in Texas?</Link></li>
        <li><Link to="/how-much-tax-do-you-pay-on-a-10000-lottery-ticket/">How Much Tax Do You Pay on a $10,000 Lottery Ticket?</Link></li>
        <li><Link to="/lottery-annuity-vs-lump-sum-which-is-better/">Lottery Annuity vs. Lump Sum: Which Is
          Better?</Link></li>
        <li><Link to="/what-to-do-if-you-win-the-lottery/">What to Do if You Win the Lottery: The Ultimate Guide</Link>
        </li>
        <li><Link to="/here-is-what-happens-when-you-win-the-lottery/">Here’s What Happens When You Win the
          Lottery</Link></li>
      </ul>
    </section>
  </Layout>

export default LotteryTaxCalculator

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
